import LobbyController from '../../controllers/lobby';
import LobbyOperations from '../../services/operations/lobby';
import lobbyApiHandler from '../../services/api/lobbyApiHandler';
import { LOBBY_TYPES } from '../../core/constants/actionTypes';

/**operations*/
const lobbyOperations = new LobbyOperations(lobbyApiHandler);

/**controller*/
const lobbyController = new LobbyController(lobbyOperations);

const lobbyConfig = {
  [LOBBY_TYPES.GET_LOBBY.CNTRL]: lobbyController.getLobby,
  [LOBBY_TYPES.GET_WEEKLY_LEADERBOARD.CNTRL]: lobbyController.getWeeklyLeaderBoard,
  [LOBBY_TYPES.GET_GLOBAL_LEADERBOARD.CNTRL]: lobbyController.getGlobalLeaderBoard,
  [LOBBY_TYPES.GET_HISTORY.CNTRL]: lobbyController.getHistory,
  [LOBBY_TYPES.GET_TOTAL_POINTS.CNTRL]: lobbyController.getTotalPoints,
  [LOBBY_TYPES.CNTRL_GET_GLOBAL_LOBBY]: lobbyController.getGlobalLobby,
};
export default lobbyConfig;
