import React from 'react';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation';
import { avatars } from '../../../core/constants/avatarObject';

const NicknameSection = ({ setModalState,
                           user: {nickname, hasNickname, avatar},
})=> {
  const translate = useTranslation();
  return (
    <>
      <div className="user-icon-b" onClick={setModalState}>
        <div className="user-image-block" title={translate(t.EDIT_PROFILE)}>
          <img src={avatars[avatar || 0]} alt="" />
        </div>
      </div>
      <div className="head-txt-b">
        <p className="head-txt-itm" title={hasNickname ? nickname : ''}>{hasNickname ? nickname : ''}</p>
        {/*<p className="head-txt-itm" title={`${points} ${translate(t.PTS)}`}>{points} {translate(t.PTS)}</p>*/}
      </div>
    </>
  );
};

export default NicknameSection;

