import React, { useState } from 'react';
import useTranslation from '../../../core/customHooks/useTranslation';
import { LEADERBOARD_TABS } from '../../../core/constants/navigationConstants';
import t from '../../../core/staticData/translation';
import { getPartnerSettings } from '../../../state-management/accessors/partnerAccessors';
import { connect } from 'react-redux';

const leaderBoardTabs = [
  {
    id: LEADERBOARD_TABS.PRIZE,
    name: t.INFORMATION,
    icon: false
  },
  {
    id: LEADERBOARD_TABS.LEADERBOARD,
    name: t.LEADERBOARD,
    icon: true
  }
];

const LeaderBoardTabs = ({
                           activeUserTotalPoints,
                           setActiveLeaderBoardTab,
                           setActiveUserTotalPoints,
                           displayUserPoints
                         }) => {
  const translate = useTranslation();
  const [ active, setActive ] = useState(false)

  const handleChangeTab = (tabId) => {
    setActiveLeaderBoardTab(tabId)
    if (tabId === LEADERBOARD_TABS.LEADERBOARD && activeUserTotalPoints) {
      setActiveUserTotalPoints(false)
    } else if (tabId !== LEADERBOARD_TABS.LEADERBOARD) {
      setActive(false)
      setActiveUserTotalPoints(false)
    }
  }

  return (
    <div className="leaderboard-title">
      <div className="inside-leaderboard-title">
        { leaderBoardTabs.map(tab => (
          <div key={ tab.id }
               className="tab-item-of-l-title"
               onClick={ () => handleChangeTab(tab.id)}
               title={ translate(tab.name) }
          >
            { translate(tab.name) }

            {displayUserPoints && tab.icon &&
              <div className={ `leaderboard-filter ${ active ? 'active' : '' }` }>
                <div className="leaderboard-arrow-icon" onClick={ (e) => {
                  setActive(!active)
                  e.stopPropagation()
                } }>
                  <span className={ active ? 'bc-i-small-arrow-up' : 'bc-i-small-arrow-down' }/>
                </div>

                <div className="leaderboard-dropdown">
                  <div
                    className="leaderboard-dropdown-item"
                    onClick={ () => {
                      setActiveUserTotalPoints(!activeUserTotalPoints)
                      setActive(false)
                    } }
                  >
                    <span>{ translate(t.CURRENT_MONTH) }</span>
                    { activeUserTotalPoints && <span className="bc-i-checked"></span> }
                  </div>
                </div>
              </div>
            }
          </div>
        )) }
        <div className="underline-block-of-title">
          <span className="inside-underline-b-of-title"/>
        </div>
      </div>


    </div>
  )
}

const mapStateToProps = state => ({
  displayUserPoints: getPartnerSettings(state).displayUserPoints,
});

export default connect(mapStateToProps)(LeaderBoardTabs);
