import PredictorWebApiHandler from '../pedictorWebApiHandler';
import {abortFetch} from '../../../core/helpers/abortController';
import ABORT_API_KEYS from '../../../core/constants/abortApiKeys';

const END_POINTS = {
  prefix: 'Lobby',
  leaderboard: 'leaderboard',
  history: 'history',
  globalRound: 'globalRound',
  totalPoints: 'userTotalPoints'
};

class LobbyApiHandler extends PredictorWebApiHandler {
  getLobby(payload) {
    const abortSignal = abortFetch(ABORT_API_KEYS.getLobby);
    return this.get('', payload, abortSignal)
  }

  getGlobalLobby(payload) {
    const abortSignal = abortFetch(ABORT_API_KEYS.getGlobalLobby);
    return this.get(END_POINTS.globalRound, payload, abortSignal);
  }

  getLeaderBoard(query) {
    return this.get(END_POINTS.leaderboard, query)
  }

  getHistory(query){
    return this.get(END_POINTS.history, query)
  }

  getTotalPoints(query) {
    return this.post(END_POINTS.totalPoints, query)
  }
}

const lobbyApiHandler = new LobbyApiHandler(END_POINTS.prefix);
export default lobbyApiHandler;
