import Controller from '../../core/classes/AppController';
import {
  rxGetGlobalLeaderBoardPending,
  rxGetGlobalLeaderBoardDone,
  rxGetLobbyDone,
  rxGetLobbyPending,
  rxGetWeeklyLeaderBoardDone,
  rxGetWeeklyLeaderBoardPending, rxGetHistoryPending, rxGetHistoryDone, rxGetTotalPointsPending, rxGetTotalPointsDone,
} from '../../state-management/actions/lobbyActions';

class LobbyController extends Controller {
  constructor(lobbyOperations) {
    super();
    this.lobbyOperations = lobbyOperations;
  }

  getLobby = async (store, action) => {
      store.dispatch(rxGetLobbyPending());
      const response = await this.lobbyOperations.getLobby(store, action);
      store.dispatch(rxGetLobbyDone(response));
  };

  getGlobalLobby = async (store, action) => {

    store.dispatch(rxGetLobbyPending());
    const response = await this.lobbyOperations.getGlobalLobby(store, action);
    if(action.payload.skip === 0) {
      store.dispatch(rxGetLobbyDone({
        ...response,
        round: response.globalRound,
        globalRound: true}));
    } else {
      const matches = store.getState().lobby.data.matches;
      store.dispatch(rxGetLobbyDone({
        ...response,
        round: response.globalRound,
        globalRound: true,
        matches: [...matches, ...response.matches],
      }));
    }
  };

  getWeeklyLeaderBoard = async (store, action) => {
      const weeklyLeaderBoard =  store.getState().weeklyLeaderBoard.data.users;
      store.dispatch(rxGetWeeklyLeaderBoardPending());
      const response = await this.lobbyOperations.getLeaderBoard(store, action);
      if(action.payload.skip !== 0){
        store.dispatch(rxGetWeeklyLeaderBoardDone({count: response.count,
                                                         users:[...weeklyLeaderBoard, ...response.users],
                                                         user: response.user}));
      } else {
        store.dispatch(rxGetWeeklyLeaderBoardDone({count: response.count, users: response.users, user: response.user}));
      }
  };

  getGlobalLeaderBoard = async (store, action) => {
      store.dispatch(rxGetGlobalLeaderBoardPending());
      const response = await this.lobbyOperations.getLeaderBoard(store, action);
      const globalLeaderBoard = store.getState().globalLeaderBoard.data.users;
      store.dispatch(rxGetGlobalLeaderBoardDone({count: response.count,
        users:[...globalLeaderBoard, ...response.users], user: response.user}));

    };


  getHistory = async (store, action) => {
    store.dispatch(rxGetHistoryPending());
    const response = await this.lobbyOperations.getHistory(store, action);
    store.dispatch(rxGetHistoryDone(response));
  };

  getTotalPoints = async (store, action) => {
    store.dispatch(rxGetTotalPointsPending());
    const response = await this.lobbyOperations.getTotalPoints(store, action);
    console.log('...response', response)
    store.dispatch(rxGetTotalPointsDone(response));
  };

}

export default LobbyController;
