import { LOBBY_TYPES } from '../../core/constants/actionTypes';

export const cntrlGetLobby = payload => ({
  type: LOBBY_TYPES.GET_LOBBY.CNTRL,
  payload,
});

export const rxGetLobbyDone = payload => ({
  type: LOBBY_TYPES.GET_LOBBY.DONE,
  payload,
});

export const rxGetLobbyPending = payload => ({
  type: LOBBY_TYPES.GET_LOBBY.PENDING,
  payload,
});

export const cntrlGetGlobalLobby = payload => ({
  type: LOBBY_TYPES.CNTRL_GET_GLOBAL_LOBBY,
  payload,
});


export const cntrlGetWeeklyLeaderBoard = payload => ({
  type: LOBBY_TYPES.GET_WEEKLY_LEADERBOARD.CNTRL,
  payload,
});
export const rxGetWeeklyLeaderBoardDone = payload => ({
  type: LOBBY_TYPES.GET_WEEKLY_LEADERBOARD.DONE,
  payload,
});
export const rxGetWeeklyLeaderBoardPending = payload => ({
  type: LOBBY_TYPES.GET_WEEKLY_LEADERBOARD.PENDING,
  payload,
});

export const cntrlGetGlobalLeaderBoard = payload => ({
  type: LOBBY_TYPES.GET_GLOBAL_LEADERBOARD.CNTRL,
  payload,
});
export const rxGetGlobalLeaderBoardDone = payload => ({
  type: LOBBY_TYPES.GET_GLOBAL_LEADERBOARD.DONE,
  payload,
});
export const rxGetGlobalLeaderBoardPending = payload => ({
  type: LOBBY_TYPES.GET_GLOBAL_LEADERBOARD.PENDING,
  payload,
});

export const cntrlGetHistory = payload => ({
  type: LOBBY_TYPES.GET_HISTORY.CNTRL,
  payload
});

export const rxGetHistoryPending = payload => ({
  type: LOBBY_TYPES.GET_HISTORY.PENDING
});

export const rxGetHistoryDone = payload => ({
  type: LOBBY_TYPES.GET_HISTORY.DONE,
  payload
})

export const cntrlGetTotalPoints = payload => ({
  type: LOBBY_TYPES.GET_TOTAL_POINTS.CNTRL,
  payload
});

export const rxGetTotalPointsPending = payload => ({
  type: LOBBY_TYPES.GET_TOTAL_POINTS.PENDING
});

export const rxGetTotalPointsDone = payload => ({
  type: LOBBY_TYPES.GET_TOTAL_POINTS.DONE,
  payload
})
