import { createAsyncActionStatuses } from '../helpers/createAsyncActionStatuses';

/**App action types*/
export const APP_ACTION_TYPES = {
  APP_INIT: 'APP_INIT',
};

/**Auth action types*/
export const AUTH_ACTION_TYPES = {
  RX_SET_IS_AUTH_BOOL: 'RX_SET_IS_AUTH_BOOL',
  CNTRL_AUTH_SCHEME: 'CNTRL_AUTH_SCHEME',
  CNTRL_AUTH_WITH_USERNAME: 'CNTRL_AUTH_WITH_USERNAME',
  CNTRL_LOG_OUT: 'CNTRL_LOG_OUT',
};

export const TRANSLATION_TYPES = {
  CNTRL_GET_TRANSLATIONS: 'CNTRL_GET_TRANSLATIONS'
}


/**Competition action types*/
export const COMPETITION_TYPES = {
  CNTRL_GET_COMPETITIONS: 'CNTRL_GET_COMPETITIONS',
  RX_GET_COMPETITIONS: 'RX_GET_COMPETITIONS',
  RX_GET_COMPETITIONS_PENDING: 'RX_GET_COMPETITIONS_PENDING',
  RX_GET_COMPETITIONS_DONE: 'RX_GET_COMPETITIONS_DONE',

  GET_ROUNDS_BY_COMPETITION_ID: createAsyncActionStatuses('GET_ROUNDS_BY_COMPETITION_ID')
};

/**Prediction action types*/
export const PREDICTION_TYPES = {
  GET_SMART_PICK : createAsyncActionStatuses('GET_SMART_PICK'),
};

/**Partner action types*/
export const PARTNER_TYPES = {
  GET_CURRENT_GLOBAL_ROUND: createAsyncActionStatuses('GET_CURRENT_GLOBAL_ROUND'),
  CNTRL_GET_PARTNER_COLORS: 'CNTRL_GET_PARTNER_COLORS',
  RX_GET_PARTNER_COLORS: 'RX_GET_PARTNER_COLORS',
  RX_GET_PARTNER_COLORS_PENDING: 'RX_GET_PARTNER_COLORS_PENDING',
  RX_GET_PARTNER_COLORS_DONE: 'RX_GET_PARTNER_COLORS_DONE',
  GET_PARTNER_SETTINGS: createAsyncActionStatuses('GET_PARTNER_SETTINGS'),
  GET_COMPETITIONS: createAsyncActionStatuses('GET_COMPETITIONS'),
};

/**User action types*/
export const  USERS_TYPES = {
  GET_USER_INFO: createAsyncActionStatuses('GET_USER_INFO'),
  POST_USER_INFO: createAsyncActionStatuses('POST_USER_INFO'),
  CNTRL_CHECK_USERNAME: 'CNTRL_CHECK_USERNAME',
  RX_SET_NICKNAME_ERROR_BOOL: 'RX_SET_NICKNAME_ERROR_BOOL',
  CNTRL_GENERATE_NICKNAME: 'CNTRL_GENERATE_NICKNAME',

  CNTRL_CHANGE_CHRISTMAS_MOOD: 'CNTRL_CHANGE_CHRISTMAS_MOOD'
};

/**Lobby action types*/
export const LOBBY_TYPES = {
  GET_LOBBY: createAsyncActionStatuses('GET_LOBBY'),
  GET_WEEKLY_LEADERBOARD: createAsyncActionStatuses('GET_WEEKLY_LEADERBOARD'),
  GET_GLOBAL_LEADERBOARD: createAsyncActionStatuses('GET_GLOBAL_LEADERBOARD'),
  GET_HISTORY: createAsyncActionStatuses('GET_HISTORY'),
  GET_TOTAL_POINTS: createAsyncActionStatuses('GET_TOTAL_POINTS'),
  CNTRL_GET_GLOBAL_LOBBY: 'CNTRL_GET_GLOBAL_LOBBY',
};

export const STATISTICS_TYPES = {
  GET_COMPETITIONS_TABLES: createAsyncActionStatuses('GET_COMPETITIONS_TABLES'),
  CNTRL_GET_COMPETITION_TABLE: 'CNTRL_GET_COMPETITION_TABLE',

  GET_MATCH_STAT: createAsyncActionStatuses('GET_MATCH_STAT'),
};

export const PRIZE_INFO_TYPES = {
  GET_PRIZE_INFO: createAsyncActionStatuses('GET_PRIZE_INFO'),
  GET_CURRENT_DISTRIBUTIONS: createAsyncActionStatuses('GET_CURRENT_DISTRIBUTIONS'),
};

export const INFO_TYPES = {
  GET_INFO: createAsyncActionStatuses('GET_INFO'),
};

export const CURRENCY_TYPES = {
  CNTRL_GET_CURRENCIES: 'CNTRL_GET_CURRENCIES',
  RX_GET_CURRENCIES: 'RX_GET_CURRENCIES',
  RX_GET_CURRENCIES_PENDING: 'RX_GET_CURRENCIES_PENDING',
  RX_GET_CURRENCIES_DONE: 'RX_GET_CURRENCIES_DONE',
};

export const SIGNAL_R_TYPES = {
  CREATE_PREDICTIONS_CONNECTION: 'CREATE_PREDICTIONS_CONNECTION',
  CREATE_LIVE_MATCHES_CONNECTION: 'CREATE_LIVE_MATCHES_CONNECTION',
  CREATE_LEADERBOARD_CONNECTION: 'CREATE_LEADERBOARD_CONNECTION',
  SET_ROUND_AND_USER: 'SET_ROUND_AND_USER',
  CNTRL_SET_LEADERBOARD_POSITION: 'CNTRL_SET_LEADERBOARD_POSITION',
  CNTRL_CLOSE_PREDICTIONS_CONNECTION: 'CNTRL_CLOSE_PREDICTIONS_CONNECTION',
  CNTRL_CLOSE_LEADERBOARD_CONNECTION: 'CNTRL_CLOSE_LEADERBOARD_CONNECTION',

  CNTRL_CREATE_ROUNDS_CONNECTION: 'CNTRL_CREATE_ROUNDS_CONNECTION',
  CNTRL_SET_PARTNER_ID: 'CNTRL_SET_PARTNER_ID'

};

export const SOCIAL_MEDIA_TYPES = {
  CNTRL_GET_SHARED_TOKEN: 'CNTRL_GET_SHARED_TOKEN',
};

export const ROUND_TYPES = {
  CNTRL_GET_DISTRIBUTION: 'CNTRL_GET_DISTRIBUTION',
  RX_GET_DISTRIBUTION: 'RX_GET_DISTRIBUTION',
  RX_GET_DISTRIBUTION_PENDING: 'RX_GET_DISTRIBUTION_PENDING',
  RX_GET_DISTRIBUTION_DONE: 'RX_GET_DISTRIBUTION_DONE',

  CNTRL_BET: "CNTRL_BET",
};

export const MAINTENANCE_TYPES = {
  RX_SET_IS_UNDER_MAINTENANCE: 'RX_SET_IS_UNDER_MAINTENANCE'
};
