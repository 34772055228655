import img1 from '../../images/avatar/1.png';
import img2 from '../../images/avatar/2.png';
import img3 from '../../images/avatar/3.png';
import img4 from '../../images/avatar/4.png';
import img5 from '../../images/avatar/5.png';
import img6 from '../../images/avatar/6.png';
import img7 from '../../images/avatar/7.png';
import img8 from '../../images/avatar/8.png';
import img9 from '../../images/avatar/9.png';

export const avatars = [img1, img2, img3, img4, img5, img6, img7, img8, img9];
