import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { controllerMiddleware } from '../middlewares';
import configs from '../../configs/config';
import * as reducers from '../reducers';
import { currentGlobalRoundReducer } from '../reducers';
import { currentDistributionsReducer } from '../reducers';
import { distributionReducer } from '../reducers/roundReducers';


const rootReducers = combineReducers({
  authState: reducers.authState,
  userInfo: reducers.userInfoReducer,
  lobby: reducers.lobbyReducers,
  weeklyLeaderBoard: reducers.weeklyLeaderBoardReducer,
  globalLeaderBoard: reducers.globalLeaderBoardReducer,
  competitionsTables: reducers.competitionsTablesReducer,
  matchStats: reducers.matchStatsReducer,
  history: reducers.historyReducer,
  totalPoints: reducers.totalPointsReducer,
  globalRound: currentGlobalRoundReducer,
  prizeInfo: reducers.prizeInfoReducer,
  information: reducers.infoReducer,
  nicknameError: reducers.checkNicknameReducer,
  currentDistributions: currentDistributionsReducer,
  currencies: reducers.currencyReducer,
  partnerSettings: reducers.partnerSettingsReducer,
  distribution: distributionReducer,
  hasPartnerColors: reducers.hasPartnerColors,
  underMaintenance: reducers.maintenanceReducer,
  competitions: reducers.competitionsReducer
});

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(controllerMiddleware(configs))
);


const store = createStore(rootReducers, enhancer);
export default store;
