export const getMatches = state => state.lobby.data.matches;
export const getRoundInfo = state =>state.lobby.data.round;
export const getRoundId = state => state.lobby.data.round.id;
export const getUserId = state => state.lobby.data.user ? state.lobby.data.user.id : null;

export const getLobby = state => state.lobby;
export const getGlobalLeaderBoard = state => state.globalLeaderBoard;
export const getWeeklyLeaderBoard = state => state.weeklyLeaderBoard;
export const getHistory = state => state.history.data;
export const getTotalPoints = state => state.totalPoints.data;
export const lobbyIsLoading = state => state.lobby.isLoading;
export const getIsCurrent = state => state.lobby.data.round.isCurrent;
export const getRoundStatus = state => state.lobby.data.round.status;
export const getCanDoublePrediction = state => state.lobby.data.round.canDoublePrediction;

