import React, { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { getTotalPoints } from '../../../state-management/accessors/lobbyAccessors';
import { cntrlGetTotalPoints } from '../../../state-management/actions/lobbyActions';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation.json';

const UserTotalPoints = ({ totalPoints, getTotalPoints }) => {

  const translate = useTranslation();

  function getCurrentMonthDates() {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

    const formatDate = (date) => {
      return date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
    };

    return {
      start: formatDate(firstDay),
      end: formatDate(today),
    };
  }

  useEffect(() => {
    getTotalPoints(getCurrentMonthDates())
  }, [])

  return (
    <div className="global-container">
      <div className="inside-global-c-wr">
        <div className="top-of-inside-global-c-wr">
          <div className="info-row title">
            <div className="info-cell title">#</div>
            <div className="info-cell title">{ translate(t.USER) }</div>
            <div className="info-cell title">{ translate(t.POINTS) }</div>
          </div>
        </div>
        <div className="bottom-of-inside-global-c-wr">

          <div className="global-wrapper">

            <div className="leaderboard-row-wr">
              <div className="info-row item total-points" >
                <div className="info-cell">1</div>
                <div className="info-cell">{ totalPoints?.id }</div>
                <div className="info-cell">{ totalPoints?.totalPoints }</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

const mapStateToProps = state => ({
  totalPoints: getTotalPoints(state)
});

const mapDispatchToProps = dispatch => ({
  getTotalPoints: payload => dispatch(cntrlGetTotalPoints(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(UserTotalPoints));

