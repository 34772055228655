import React, { useState, useRef } from 'react';
import {
  cntrlCheckUserName,
  cntrlGenerateNickname,
  cntrlPostUserInfo
} from '../../../state-management/actions/usersActions';
import { connect } from 'react-redux';
import VALIDATIONS from '../../../core/constants/validations';
import {getNicknameError, getUserInfo} from '../../../state-management/accessors/usersAccessors';
import useDidUpdateEffect from '../../../core/customHooks/useDidUpdateEffect';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation';
import {cntrlGetLobby} from '../../../state-management/actions/lobbyActions';
import PartnerModule from '../../../core/moduls/PartnerModul';
import {APP_CONSTANTS} from '../../../core/constants/constants';
import {avatars} from '../../../core/constants/avatarObject';

const DIRECTIONS = {
  RIGHT: 'right',
  LEFT: 'left',
};

const NICKNAME_ERROR_MESSAGES = {
  LENGTH_ERROR: t.THE_NICKNAME_MUST_BE_4_30_CHARACTERS,
  REPEAT_ERROR: t.USERNAME_ALREADY_TAKEN,
};

const NICKNAME_MIN_LENGTH = 4;


const UserPopup =({ user : { nickname, hasNickname, avatar: userAvatar },
                    setModalState,
                    postUserInfo,
                    checkNickname,
                    nicknameError,
                    generateNickname,
                    getLobby,
}) => {
  const translate = useTranslation();
  const [ avatar, setAvatar ] = useState(1);
  const [ nicknameInputValue, setNicknameInputValue ] = useState(hasNickname? nickname: '');
  const [ activeConfirmButton, setActiveConfirmButton ] = useState(false);
  const [ activeCloseButton, setActiveCloseButton ] = useState(true);
  const [ errorMessage, setErrorMessage ] = useState(null);

  const activeButton = useRef(true);

  const handleNicknameInputChange = (e) => {
    const value = e.target.value;
    if (VALIDATIONS.NICKNAME_INPUT.test(value)) {
      setNicknameInputValue(value);
      errorMessage && setErrorMessage(null);
    }
  };

  useDidUpdateEffect(() => {
    VALIDATIONS.NICKNAME_SUBMIT.test(nicknameInputValue) && (nicknameInputValue !== nickname || avatar !== userAvatar) ?
      setActiveConfirmButton(true):
      setActiveConfirmButton(false);
  }, [nicknameInputValue, avatar]);

  const handleAvatarChange = (direction) => {
    setAvatar((prev) => {
      if (direction === DIRECTIONS.LEFT) {
        return prev === 0 ? avatars.length - 1 : prev - 1;
      } else if (direction === DIRECTIONS.RIGHT) {
        return prev === avatars.length - 1 ? 0 : prev + 1;
      }
      return prev;
    });

    VALIDATIONS.NICKNAME_SUBMIT.test(nicknameInputValue) && setActiveConfirmButton(true);
  };

  const changeUserInfo = async (e) => {
    e.preventDefault();
    if ( nickname === nicknameInputValue && userAvatar === avatar) {
      setModalState('');
      return;
    }

    if (nicknameInputValue.length < NICKNAME_MIN_LENGTH) {
      setErrorMessage(NICKNAME_ERROR_MESSAGES.LENGTH_ERROR);
      return;
    }

    if (activeButton.current) {
      activeButton.current = false;
      if (nickname !== nicknameInputValue) {
        const response = await checkNickname({nickname: nicknameInputValue});
        if (response) {
          setErrorMessage(NICKNAME_ERROR_MESSAGES.REPEAT_ERROR);
          activeButton.current = true;
          return;
        }
      }

      const payload = nicknameInputValue === nickname ?
        {
          avatar: avatar,
        } :
        {
          nickname: nicknameInputValue,
          avatar: avatar,
        };
      postUserInfo(payload).then(res => {
        !res && setModalState('');
        activeButton.current = true;
      });
    }


  };

  const handleClose = () => {
    if(hasNickname) {
      setModalState('');
      return;
    }
    if(!activeCloseButton){
      return;
    }
    setActiveCloseButton(false);
    generateNickname()
      .then(res => {
      const payload = {
        nickname: res,
        avatar: avatar,
      };
      postUserInfo(payload).then(res => {
        res && setActiveCloseButton(true);
      });
    })
  };

  useDidUpdateEffect(() => {
    if(hasNickname) {
      const partnerId = PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID);
      getLobby({partnerId});
      setModalState('');
    }
  }, [hasNickname]);



  return (
    <div className="wr-user-popup-layout">
      <div className="user-popup-layout">
        <div className="top-of-user-popup-layout">
          <div className="popup-profile-header">
            <p className="popup-profile-name" title={translate(t.PLAYER_PROFILE)}>{translate(t.PLAYER_PROFILE)}</p>
            <div className="popup-profile-header-icon" onClick={handleClose}>
              <span className="bc-i-close"/>
            </div>
          </div>
        </div>
        <div className="bottom-of-user-popup-layout">
          <div className="scroll-wr">
            <form className="popup-profile-content" onSubmit={changeUserInfo}>
              <p className="popup-profile-avatar" title={translate(t.AVATAR)}>{translate(t.AVATAR)}</p>
              <div className="popup-profile-avatar-d">
                <div className="icon-right" onClick={() => handleAvatarChange(DIRECTIONS.RIGHT)}> <span className="bc-i-small-arrow-right"/> </div>
                <div className="icon-left" onClick={()=>handleAvatarChange(DIRECTIONS.LEFT)}> <span className="bc-i-small-arrow-left"/> </div>
                <div className="popup-profile-avatar-d-flex">
                  <div className="popup-profile-avatar-img">
                    <div className="popup-profile-avatar-img-flex">
                      <img src={avatars[avatar === 0 ? avatars.length - 1 : avatar - 1]} alt="" onClick={() => handleAvatarChange(DIRECTIONS.LEFT)} />
                    </div>
                  </div>
                  <div className="popup-profile-avatar-img active">
                    <div className="popup-profile-avatar-img-flex">
                      <img src={avatars[avatar]} alt=""/>
                    </div>
                  </div>
                  <div className="popup-profile-avatar-img">
                    <div className="popup-profile-avatar-img-flex">
                      <img src={avatars[avatar === avatars.length - 1 ? 0 : avatar + 1]} alt="" onClick={()=>handleAvatarChange(DIRECTIONS.RIGHT)}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="popup-profile-nichname-d">
                <div className="popup-profile-avatar" title={translate(t.NICKNAME)}>{translate(t.NICKNAME)}</div>
                <div className="popup-profile-nichname-input">
                  <input type="text"
                         className="input-nichname"
                         spellCheck="false"
                         placeholder={translate(t.YOUR_NICKNAME)}
                         title={translate(t.YOUR_NICKNAME)}
                         value={nicknameInputValue}
                         onChange={handleNicknameInputChange}/>
                  <div className="icon-block-inp">
                    <span className="bc-i-lock"/>
                  </div>
                  <p className="popup-profile-nichname-error-massage" title={translate(errorMessage)}>{translate(errorMessage || t.ONLY_ENGLISH)}</p>
                </div>
              </div>
              <div className="popup-profile-button-d">
                <button
                  className={`popup-profile-button ${activeConfirmButton ? 'active' : ''}`}
                  type="submit"
                  title={translate(t.CONFIRM)}
                >
                  {translate(t.CONFIRM)}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: getUserInfo(state),
  nicknameError: getNicknameError(state),
});

const mapDispatchToProps = dispatch => ({
  postUserInfo: payload => dispatch(cntrlPostUserInfo(payload)),
  checkNickname: (payload, isSameNickname)=> dispatch(cntrlCheckUserName(payload, isSameNickname)),
  generateNickname: () => dispatch(cntrlGenerateNickname()),
  getLobby: payload => dispatch(cntrlGetLobby(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPopup);

