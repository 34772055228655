import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import PartnerModule from './core/moduls/PartnerModul';
import { Provider } from 'react-redux';
import { BrowserRouter} from 'react-router-dom';
import App from './view/app/app';
import * as serviceWorker from './serviceWorker';
import store from './state-management/store';
import Splash from './view/containers/splash';
import { SkinningHelperInit } from './core/helpers/skinningHelper';
import translationService from './services/services/TranslationService';
import { TranslationContext } from './contexts';

PartnerModule.init();

const AppWrapper = props =>{
  const [ translations, setTranslations ] = useState({});
  const hasPartnerColors = store.getState().hasPartnerColors.data;

  useEffect(() => {
    !hasPartnerColors && SkinningHelperInit();
    const subscriberId = translationService.subscribe(setTranslations);
    return () => translationService.unsubscribe(subscriberId)
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <TranslationContext.Provider value={translations}>
          <Splash>
            <App/>
          </Splash>
        </TranslationContext.Provider>
      </BrowserRouter>
    </Provider>
  );
};

SkinningHelperInit().then(()=> {
  ReactDOM.render(<AppWrapper />, document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
