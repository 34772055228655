import PredictorTranslationApiHandler from '../predictorTranslationApiHandler';

const END_POINTS = {
  prefix: 'Translations',
};

class TranslationsApiHandler extends PredictorTranslationApiHandler {
  constructor(prefix) {
    super(prefix);
  }

  getTranslations(query) {
    return this.get('',query);
  }
}

const translationsApiHandler = new TranslationsApiHandler(END_POINTS.prefix);
export default translationsApiHandler;
