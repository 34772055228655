import React, { useContext } from 'react';
import LeaderBoardTabs from './leaderBoardTabs';
import PrizeSection from './prizeSection';
import RoundLeaderBoard from './roundLeaderBoard';
import { LEADERBOARD_TABS } from '../../../core/constants/navigationConstants';
import { getRoundStatus } from '../../../state-management/accessors/lobbyAccessors';
import { connect } from 'react-redux';
import useDidUpdateEffect from '../../../core/customHooks/useDidUpdateEffect';
import { EGameStatuses, ERoundStatuses } from '../../../core/constants/enums';
import { getDistribution } from '../../../state-management/accessors/roundAccessors';
import { NavigationContext } from '../../../contexts';
import UserTotalPoints from './userTotalPoints';

const LeaderBoard = ({ roundStatus, distribution, blockContent, isDisabled }) => {

  const {
    activeLeaderBoardTab,
    activeUserTotalPoints,
    setActiveLeaderBoardTab,
    setActiveUserTotalPoints
  } = useContext(NavigationContext);

  useDidUpdateEffect(() => {
    if (roundStatus === ERoundStatuses.UPCOMING && activeLeaderBoardTab === LEADERBOARD_TABS.LEADERBOARD) {
      setActiveLeaderBoardTab(LEADERBOARD_TABS.PRIZE);
    }
    if (roundStatus !== EGameStatuses.UPCOMING && activeLeaderBoardTab === LEADERBOARD_TABS.PRIZE) {
      setActiveLeaderBoardTab(LEADERBOARD_TABS.LEADERBOARD)
    }
  }, [ distribution ]);

  return (
    <div className="right-column">
      <div className="right-container-wr">
        <div className={ `leaderboard-layout ${ activeLeaderBoardTab ? 'next' : '' }` }> {/*add class next*/ }
          <LeaderBoardTabs
            activeUserTotalPoints={ activeUserTotalPoints }
            setActiveLeaderBoardTab={ setActiveLeaderBoardTab }
            setActiveUserTotalPoints={ setActiveUserTotalPoints }
          />
          <div className="leaderboard-body">
            <div className="body-wr-right-cont">
              <PrizeSection/>
              { activeUserTotalPoints ?  <UserTotalPoints /> :
                <RoundLeaderBoard isDisabled={ isDisabled }/> }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

const mapStateToProps = state => ({
  roundStatus: getRoundStatus(state),
  distribution: getDistribution(state),
});

export default connect(mapStateToProps)(LeaderBoard);
